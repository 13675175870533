








/* eslint-disable no-unused-expressions */
import { GenerateWallet } from '@/helpers/Home/Redeem'
import Vue from 'vue'
export default Vue.extend({
  name: 'Home',
  data: () => ({
    src: ''
  }),
  components: {
  },
  methods: {
  },
  async mounted () {
    this.src = await GenerateWallet('12345', true) as string
  }
})
